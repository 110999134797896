import $ from "jquery";
import "selectize/dist/js/standalone/selectize";

$(document).on('turbolinks:load', function(){
  $("#book_tag_ids").selectize({
    create: true,
    sortField: 'text',
    searchField: 'search'
  })
})
