import "@nuux/sentry"

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "../application/bootstrap"
import "../application/books"
// CSS
import "application.sass"

// Images
require.context('../../images', true)
